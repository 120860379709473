import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { LocalizedImage } from "components/LocalizedImage"

/**
 * 患者用メニュー
 */
export const KrankeMenu = ({ isInviewfadeInUp = false }) => {
  const { t } = useTranslation()

  return (
    <div className={`kranke_menu ${isInviewfadeInUp && "inviewfadeInUp"}`}>
      <div className={`kranke_wrap ${isInviewfadeInUp && "inviewfadeInUp"}`}>
        <Link to="/appointment_menu">
          <div className="kranke_menu_box beginer">
            <div className="text">{t("外来のご案内")}</div>
            <p className="icon">
              <LocalizedImage
                path="/img/top/outpatient_color.jpg"
                alt={t("外来のご案内", "外来のご案内")}
              />
            </p>
          </div>
        </Link>
      </div>
      <div
        className={`kranke_wrap ${
          isInviewfadeInUp && "inviewfadeInUp"
        } delay_2`}
      >
        <Link to="/hospitalization_guidance">
          <div className="kranke_menu_box repeat">
            <p className="text">{t("入院・面会の方", "入院・面会の方")}</p>
            <p className="icon">
              <LocalizedImage
                path="/img/top/hospitalization_color.jpg"
                alt={t("入院・面会の方", "入院・面会の方")}
              />
            </p>
          </div>
        </Link>
      </div>
      <div
        className={`kranke_wrap ${
          isInviewfadeInUp && "inviewfadeInUp"
        } delay_4`}
      >
        <Link to="/institutions">
          <div className="kranke_menu_box hospitalization">
            <p className="text">
              {t("医療機関の皆さまへ", "医療機関の皆さまへ")}
            </p>
            <p className="icon">
              <LocalizedImage
                path="/img/top/hospital_color.jpg"
                alt={t("医療機関の皆さまへ", "医療機関の皆さまへ")}
              />
            </p>
          </div>
        </Link>
      </div>
      <div
        className={`kranke_wrap ${
          isInviewfadeInUp && "inviewfadeInUp"
        } delay_6`}
      >
        <a
          href="http://www.hoshii-contact.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="kranke_menu_box contact">
            <p className="text">{t("コンタクトレンズ", "コンタクトレンズ")}</p>
            <p className="icon">
              <LocalizedImage
                path="/img/top/contact_lens_color.jpg"
                alt={t("コンタクトレンズ", "コンタクトレンズ")}
              />
            </p>
          </div>
        </a>
      </div>
    </div>
  )
}
