import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { getImage } from "funcs/helpers/getImage"
import Footer from "components/Footer"
import { TopNewsTemplate } from "templates/topNews.js"
import { PageTopAnchor } from "components/PageTopAnchor"
import { PcFooterLink } from "components/FooterLink/Pc"
import { MoFooterLink } from "components/FooterLink/Mo"
import { MoFooterWrap } from "components/FooterWrap"
import { PhoneArea } from "components/PhoneArea"
import { RecruitArea } from "components/RecruiteArea"
import { HoursArea } from "components/HoursArea"
import { KrankeMenu } from "components/KrankeMenu"
import { BannerArea } from "components/BannerArea"

const IndexPage = () => {
  const { t } = useTranslation()
  const isInviewfadeInUp = true
  const { locale } = useLocalization()

  return (
    <main>
      <div className={`main_v ${isInviewfadeInUp && "inviewfadeInUp"}`}>
        <h1 className={`top_catch ${isInviewfadeInUp && "inviewfadeInUp"}`}>
          {t("眼の健康は私たちに", "眼の健康は私たちに")}
          <br />
          {t("お任せください", "お任せください")}
          <br />
          {locale === "ja" && <span>Let us take care of your eyes health</span>}
        </h1>
        <div className="main_bg"></div>
        <div className="main_bg"></div>
        <div className="main_bg"></div>
        <div className="main_bg"></div>
      </div>

      <div className="container2">
        <section className="content">
          <KrankeMenu isInviewfadeInUp={isInviewfadeInUp} />
        </section>
      </div>

      <section className="content">
        <HoursArea isInviewfadeInUp={isInviewfadeInUp} />
      </section>

      <section className="content news_bg inviewfadeInUp">
        <div className="container2">
          <div className="news_area">
            <Link to="/news/important">
              <div className={`${isInviewfadeInUp && "inviewfadeInUp"}`}>
                <h2 className="title">{t("重要なお知らせ")}</h2>
              </div>
            </Link>
            <TopNewsTemplate />
            <Link to="/news/important" className="name">
              <div className="inviewfadeInUp">
                <p className="link">
                  {t("重要なお知らせ一覧を見る")}
                  <i className="fa-light fa-circle-chevron-right"></i>
                </p>
              </div>
            </Link>
            <Link to="/news" className="name all">
              <div className="inviewfadeInUp">
                <p className="link">
                  {t("お知らせ一覧を見る")}
                  <i className="fa-light fa-circle-chevron-right"></i>
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <div className="container2">
        <section className="content">
          <RecruitArea isInviewfadeInUp={isInviewfadeInUp} />
        </section>
      </div>

      <div className="container2">
        <section className="content">
          <PhoneArea isInviewfadeInUp={isInviewfadeInUp} />
          <BannerArea isInviewfadeInUp={isInviewfadeInUp} />
        </section>
      </div>

      <section className="content">
        <div className={`footer_link ${isInviewfadeInUp && "inviewfadeInUp"}`}>
          <PcFooterLink />
          <MoFooterLink />
        </div>
      </section>

      <MoFooterWrap />
      <Footer></Footer>
      <PageTopAnchor />
    </main>
  )
}

export default IndexPage
