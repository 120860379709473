import { useTranslation } from "react-i18next"

// 掲載期間内でなければ 404 的な表示する
export const NoNewsFound = () => {
  const { t } = useTranslation()

  return (
    <div style={{ width: "auto", height: "100%" }}>
      {t("表示するニュースがありません", "表示するニュースがありません")}
    </div>
  )
}
