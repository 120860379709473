import { useStaticQuery, graphql } from "gatsby"

/**
 *
 * トップページで表示するニュース
 * filter: import な news
 * sort post_date で降順
 */
export const useImportantNews = () => {
  const topNews = useStaticQuery(
    graphql`
      query ImportantNews {
        allContentfulArticle(
          filter: { newsMeta: { type: { eq: "important" } } }
          sort: { fields: newsMeta___post_date, order: DESC }
        ) {
          edges {
            node {
              contentful_id
              id
              title
              newsMeta {
                post_date(formatString: "YYYY-MM-DD")
                start_publish_datetime
                end_publish_datetime
              }
              newsContent {
                newsContent
              }
              node_locale
            }
          }
        }
      }
    `,
  )
  return topNews.allContentfulArticle
}
