import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { LocalizedImage } from "components/LocalizedImage"

export const RecruitArea = ({ isInviewfadeInUp = true }) => {
  const { t } = useTranslation()

  return (
    <div className={`recruit_area ${isInviewfadeInUp && "inviewfadeInUp"}`}>
      <Link to="/recruit">
        <h2>{t("採用情報", "採用情報")}</h2>
      </Link>
      <div className="wrap">
        <div className={`${isInviewfadeInUp && "inviewfadeInUp"}`}>
          <Link to="/recruit#doctor">
            <LocalizedImage
              path="/img/top/doctor.jpg"
              alt={t("医師", "医師")}
              loading="lazy"
            />
            <p>{t("医師", "医師")}</p>
            <p>
              <i className="fal fa-chevron-circle-right"></i>
            </p>
          </Link>
        </div>
        <div className={`${isInviewfadeInUp && "inviewfadeInUp"} delay_2`}>
          <Link to="/recruit#nurce">
            <LocalizedImage
              path="/img/top/nurce.jpg"
              locale={undefined}
              alt={t("看護師", "看護師")}
              loading="lazy"
            />
            <p>{t("看護師", "看護師")}</p>
            <p>
              <i className="fal fa-chevron-circle-right"></i>
            </p>
          </Link>
        </div>
        <div className={`${isInviewfadeInUp && "inviewfadeInUp"} delay_4`}>
          <Link to="/recruit#pharmacist">
            <LocalizedImage
              path="/img/top/pharmacist.jpg"
              locale={undefined}
              alt={t("薬剤師", "薬剤師")}
              loading="lazy"
            />
            <p>{t("薬剤師", "薬剤師")}</p>
            <p>
              <i className="fal fa-chevron-circle-right"></i>
            </p>
          </Link>
        </div>
        <div className={`${isInviewfadeInUp && "inviewfadeInUp"} delay_6`}>
          <Link to="/recruit#orthoptist">
            <LocalizedImage
              path="/img/top/orthoptist.jpg"
              locale={undefined}
              alt={t("視能訓練士", "視能訓練士")}
              loading="lazy"
            />
            <p>{t("視能訓練士", "視能訓練士")}</p>
            <p>
              <i className="fal fa-chevron-circle-right"></i>
            </p>
          </Link>
        </div>
        <div className={`${isInviewfadeInUp && "inviewfadeInUp"} delay_8`}>
          <Link to="/recruit#other_staff">
            <LocalizedImage
              path="/img/top/staff.jpg"
              locale={undefined}
              alt={t("コメディカルスタッフ", "コメディカルスタッフ")}
              loading="lazy"
            />
            <p>{t("コメディカルスタッフ", "コメディカルスタッフ")}</p>
            <p>
              <i className="fal fa-chevron-circle-right"></i>
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}
