import { LocalizedLink as Link, useLocalization } from "gatsby-theme-i18n"
import { isAfter, isBefore, toDate, parseISO } from "date-fns"

import { useImportantNews } from "hooks/useImportantNews"
import { NoNewsFound } from "components/NoNewsFound"

/**
 * トップページの重要なおしらせを表示する
 */
export const TopNewsTemplate = () => {
  const { edges } = useImportantNews()
  const { locale } = useLocalization()
  const now = toDate(Date.now())

  const localizedNews = edges
    .filter(
      ({ node }) =>
        node.node_locale.slice(0, 2) === locale &&
        node.newsContent.newsContent !== "",
    )
    .filter(
      ({ node }) =>
        isBefore(now, parseISO(node.newsMeta.end_publish_datetime)) &&
        isAfter(now, parseISO(node.newsMeta.start_publish_datetime)),
    )
    .slice(0, 3) // 直近 3件のみ表示する

  if (localizedNews.length === 0) return <NoNewsFound />

  return localizedNews.map(({ node }) => (
    <div className="view_timer" key={node.contentful_id}>
      <Link to={`/news/article/${node.contentful_id}`}>
        <dl className="inviewfadeInUp">
          <dt>{node.newsMeta.post_date}</dt>
          <dd>{node.title}</dd>
        </dl>
      </Link>
    </div>
  ))
}
